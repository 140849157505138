.fc-toolbar-chunk {
  display: flex;

  @media screen and (max-width: 480px) {
    flex-direction: column;

    .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-top: .5rem;
    margin-left: 0;
  }
}
